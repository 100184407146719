import * as React from "react"
import { Row, Col, Card } from 'react-bootstrap'
import { Link } from "gatsby";
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import Seo from "@components/seo"
import "../../styles/index.scss"
import TopNav from "../../components/common/TopNav";

const CardItems = [
  {
    menuLabel: "Regions",
    icon: "icon icon-globe-b"
  },
  {
    menuLabel: "Facilities",
    icon: "icon icon-feature-b"
  },
  {
    menuLabel: "Property Default Settings",
    link: '/currency/edit',
    icon: "icon icon-currency-all-b"
  }
]

const SettingMenuCards = () => {
  return (
    <>
      <Seo title="Settings" />
      <div className="main-wrapper">
        <Sidebar menuActive="settings" />
        <div className="main-content">
          <div className="">
            <TopNav
              menu="Settings"
              parentPath="Settings"
              pageType="Settings" />
            <Row xs={1} md={3} className="g-4">
              {CardItems.map((item) => (
                <Col>
                  <Card>
                    <Card.Body>
                      <Card.Title>
                        <div style={{ 'textAlign': "center" }} className="settings-wrapper">
                          <Link to={item.link ? item.link : `/${item.menuLabel.replace(/[\s]/g, '-').toLowerCase()}/list`}>
                            <i className={item.icon}></i> <span style={{ marginLeft: '5px', position: 'relative', top: '2px' }}>{item.menuLabel}</span></Link>
                        </div>
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingMenuCards;
